import Svg from "../svg"

// isCentered = true will center the icon, title & text
// isLeftIcon = true will move the icon to the left
// choose one of the above two
// icon is name of icon
// simpleIcon=true will just put the Svg without background etc

export default function SmallFeatureUnit({
  isCentered,
  isLeftIcon,
  icon,
  iconClasses,
  simpleIcon,
  title,
  text,
}) {
  if (isLeftIcon == true)
    return (
      <div className="flex">
        <div className="flex-shrink-0">
          <Icon simpleIcon={simpleIcon} icon={icon} iconClasses={iconClasses} />
        </div>
        <div className="ml-4">
          <Title title={title} />
          <Text text={text} />
        </div>
      </div>
    )

  return (
    <div className={isCentered == true ? "text-center" : ""}>
      <Icon
        isCentered={isCentered}
        simpleIcon={simpleIcon}
        icon={icon}
        iconClasses={iconClasses}
      />
      <div className="mt-5">
        <Title title={title} />
        <Text text={text} />
      </div>
    </div>
  )
}

function Icon({ isCentered, simpleIcon, icon, iconClasses }) {
  let conClasses =
    simpleIcon == true
      ? " text-gray-700 "
      : " h-12 w-12 rounded-md bg-blue-500 text-white "

  return (
    <div
      className={
        "flex items-center justify-center " +
        conClasses +
        (isCentered == true ? " m-auto" : "")
      }
    >
      <Svg
        name={icon || "analytics"}
        classes={"h-6 w-6 " + iconClasses}
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      />
    </div>
  )
}

function Title({ title }) {
  return <dt className="text-lg leading-6 font-medium text-primary">{title}</dt>
}

function Text({ text }) {
  return <dd className="mt-2 text-base text-muted-foreground">{text}</dd>
}
