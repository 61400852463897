import Button from "@common/buttons/button"

interface CtaProps {
  mainHeader?: string
  subHeader?: string
  primaryText?: string
  secondaryText?: string
  primaryLink?: string
  secondaryLink?: string
  newTab?: boolean
}

export default function Cta({
  mainHeader = "Ready to dive in?",
  subHeader = "Schedule a demo now.",
  primaryText = "Get Started",
  secondaryText = "Learn More",
  primaryLink = "/contact?ref=cta",
  secondaryLink = "/faqs",
  newTab = false,
}: CtaProps) {
  return (
    <div className="bg-gray-50 dark:bg-opacity-5">
      <div className="max-w-8xl mx-auto py-20 px-4 sm:px-6 lg:py-24 lg:px-8 lg:flex lg:items-center lg:justify-between">
        <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
          <span className="block">{mainHeader}</span>
          <span className="block text-blue-600">{subHeader}</span>
        </h2>
        <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
          {primaryText && primaryLink && (
            <Button variant="primary" size="md">
              <a href={primaryLink} target={newTab ? "_blank" : undefined}>
                {primaryText}
              </a>
            </Button>
          )}
          {secondaryText && secondaryLink && (
            <div className="ml-3">
              <Button variant="outline" size="md">
                <a href={secondaryLink} target={newTab ? "_blank" : undefined}>
                  {secondaryText}
                </a>
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
